import { useState } from "react";
import "./Listing.css";
import ListingHeader from "./ListingHeader";
import ProfileListings from "./ProfileListings";
import { Loader } from "@fluentui/react-northstar";

export default function Listing({ allUsers, departments, setSelectedUser, toggleListingAndProfile, getInfo, isAdmin }) {
    const [searchText, setSearchText] = useState("");
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [searchedUsers, setSearchedUsers] = useState([]);

    // logic to perform filtering and searching operations on user data
    // filtering
    function calcFilteredUsers(selectedItem) {
        if (selectedItem === "All") {
            setFilteredUsers(allUsers);
        } else {

            let filteredUsersData = allUsers.filter((user) => user.department === selectedItem);
            setFilteredUsers(filteredUsersData);
        }
    }

    // searching
    function calcSearchedUsers(searchText) {
        // the check to see if filteredUsers is empty is used because the dropdown has no default
        // selection which makes the filteredUsers list empty when the page is newly loaded
        setSearchText(searchText); // set search text
        if (searchText !== "" && filteredUsers.length === 0) {
            let searchedUsersData = allUsers.filter((user) => {
                return user.displayName.toLowerCase().includes(searchText.toLowerCase());
            });
            setSearchedUsers(searchedUsersData);
        } else if (searchText !== "" && filteredUsers.length > 0) {
            let searchedUsersData = filteredUsers.filter((user) => {
                return user.displayName.toLowerCase().includes(searchText.toLowerCase());
            });
            setSearchedUsers(searchedUsersData);
        } else {
            // TODO: check how this block relates to the conditional rendering of users list
            // and see if probably you can optimize the conditions in the conditional rendering
            setSearchedUsers(filteredUsers);
        }
    };

    return (
        <div class={`main ${isAdmin ? "main-admin-view" : ""}`}>
            <div class="main-header">
                <ListingHeader
                    allUsers={allUsers}
                    departments={departments}
                    filterUsers={calcFilteredUsers}
                    searchUsers={calcSearchedUsers}
                />
            </div>

            <div class="main-content-wrapper">
                <div className={`main-content ${allUsers.length < 1 ? 'loading-indicator-parent' : ''}`} style={{ position: 'relative' }}>
                    <div>
                        {allUsers.length < 1 && <Loader style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />}
                        <ProfileListings
                            allUsers={allUsers}
                            filteredUsers={filteredUsers}
                            searchedUsers={searchedUsers}
                            searchText={searchText}
                            setSelectedUser={setSelectedUser}
                            toggleListingAndProfile={toggleListingAndProfile}
                            getInfo={getInfo}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}