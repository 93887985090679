import React, { useState } from "react";
import "./NavBar.scss";
import "../App.css";

function NavBar(props) {
    const { selectNav, isAdmin } = props;
    const [toggleLink, setToggleLink] = useState(1);

    const toggle = (i) => {
        setToggleLink(i);
    };

    return (
        <div>
            <nav className="nav-header">
                <ul>
                    <li>
                        <p
                            className={toggleLink === 1 ? "active-link" : ""}
                            onClick={() => {
                                toggle(1);
                                selectNav("Home");
                            }}
                        >
                            Home
                        </p>
                    </li>

                    {isAdmin && (
                        <li>
                            <p
                                className={toggleLink === 2 ? "active-link" : ""}
                                onClick={() => {
                                    toggle(2);
                                    selectNav("Manage Admin");
                                }}
                            >
                                Manage Admin
                            </p>
                        </li>
                    )}

                    {isAdmin && (
                        <li>
                            <p
                                className={toggleLink === 3 ? "active-link" : ""}
                                onClick={() => {
                                    toggle(3);
                                    selectNav("Manage Information");
                                }}
                            >
                                Manage Information
                            </p>
                        </li>
                    )}
                </ul>
            </nav>
        </div>
    );
}

export default NavBar;
