import './Organogram.css';
import React from 'react';

import defaultPhoto from '../../../images/default-photo.png';

export default function Organogram({ userId, reports, users }) {
    const user = users?.find((user) => user.id === userId);
    const manager = reports[0] || {};

    let hasDirectReports;
    reports.find((report) => {
        if (report.type === "directReport" && report.id) {
            hasDirectReports = true;
            return true;
        }
    });

    return (
        <div>
            <div class="organogram-container">
                <div className='manager-personal-cards-wrapper'>
                    {/* the condition is to check if the manager obj has real data and not an error obj */}
                    {manager?.id &&
                        <>
                            <p className='report-type-text'>Manager</p>
                            <div className='organogram-profile-card-container'>
                                <div className="org-photo-container">
                                    <img className='org-img-small' src={manager?.profilePhoto ? `data:image/jpeg;base64,${manager?.profilePhoto}` : defaultPhoto} alt="avatar" />
                                </div>
                                <p>{manager?.displayName ? manager?.displayName : "N/A"}</p>
                                <p>{manager?.jobTitle ? manager?.jobTitle : "N/A"}</p>
                            </div>
                        </>
                    }

                    <div className='organogram-profile-card-container'>
                        <div className="org-photo-container">
                            <img className='org-img-medium' src={user?.profilePhoto ? `data:image/jpeg;base64,${user?.profilePhoto}` : defaultPhoto} alt="avatar" />
                        </div>
                    </div>
                </div>
                <div>
                    {hasDirectReports &&
                        <p className='report-type-text' style={{ textAlign: 'center' }}>{"Direct Report(s)"}</p>
                    }
                    <div className='organogram-reports-container-wrapper'>
                        <div class="organogram-reports-container">
                            {reports.map((directReport) => {
                                if (directReport?.type === "manager") {
                                    return;
                                }
                                return (
                                    <div className='organogram-profile-card-container-2'>
                                        <div className="org-photo-container">
                                            <img className='org-img-small' src={directReport?.profilePhoto ? `data:image/jpeg;base64,${directReport?.profilePhoto}` : defaultPhoto} alt="avatar" />
                                        </div>
                                        <p>{directReport?.displayName ? directReport?.displayName : "N/A"}</p>
                                        <p>{directReport?.jobTitle ? directReport?.jobTitle : "N/A"}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
