import "./Modal.css";

import { useMediaQuery } from "react-responsive";

import btn_Cancel from "../../../images/cancel.png";
import defaultPhoto from '../../../images/default-photo.png';
import Organogram from "./Organogram";

export default function Modal({ userId, reports, users, handleChatOpening, currentHost, close }) {
    const isMobile = useMediaQuery({ query: "(max-width: 820px)" });
    const user = users?.find((user) => user.id === userId);
    const handleCloseModal = () => {
        close();
    }
    return (
        <div>
            <div className='profile-detail-wrapper'>
                <div className='profile-detail'>
                    <div className="profile-detail-header">
                        <h4>Profile Detail</h4>
                        <img
                            src={btn_Cancel}
                            alt="cancel"
                            onClick={handleCloseModal}
                        />
                    </div>

                    <div className="profile-detail-content">
                        <div className="content-header">
                            <img src={user?.profilePhoto ? `data:image/jpeg;base64,${user?.profilePhoto}` : defaultPhoto} alt="avatar" />
                            <div className="content-header-detail">
                                <h2>{user.displayName}</h2>
                                <p>{user.jobTitle || "N/A"} <span></span> {user.department || "N/A"}</p>
                                <div class="contact-links">
                                    {
                                        currentHost !== "Office" &&
                                        <a href={`mailto:${user.mail}`} class="link-email">
                                            <i class="far fa-envelope icon-message icon"></i>
                                            <p>Send Email</p>
                                        </a>
                                    }
                                    {
                                        currentHost === "Teams" && !isMobile &&
                                        <a
                                            class="link-chat"
                                            onClick={() => {
                                                handleChatOpening(user)
                                            }}
                                        >
                                            <i class="fa far fa-comment-dots icon-chat icon"></i>
                                            <p>Start Chat</p>
                                        </a>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="content-body">
                            <div className="contact-information">
                                <h4>Contact information</h4>
                                <div className="contact-information-section">
                                    <div className="contact-information-section-item">
                                        <i class="far fa-envelope"></i>
                                        <div>
                                            <p>Email</p>
                                            <p>{user.mail || "N/A"}</p>
                                        </div>
                                    </div>
                                    <div className="contact-information-section-item">
                                        <i class="fas fa-phone-alt"></i>
                                        <div>
                                            <p>Mobile</p>
                                            <p>{user.mobilePhone || "N/A"}</p>
                                        </div>
                                    </div>
                                    <div className="contact-information-section-item">
                                        <i class="far far fa-comment-dots"></i>
                                        <div>
                                            <p>Chat</p>
                                            <p>{user.mail || "N/A"}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="contact-information-section">
                                    <div className="contact-information-section-item">
                                        <i class="far fa-user"></i>
                                        <div>
                                            <p>Job Title</p>
                                            <p>{user.jobTitle || "N/A"}</p>
                                        </div>
                                    </div>
                                    <div className="contact-information-section-item">
                                        <i class="fas fa-users"></i>
                                        <div>
                                            <p>Department</p>
                                            <p>{user.department || "N/A"}</p>
                                        </div>
                                    </div>
                                    <div className="contact-information-section-item">
                                        <i class="fas fa-map-marker-alt"></i>
                                        <div>
                                            <p>Business Address</p>
                                            <p>{user.officeLocation || "N/A"}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="organization">
                                <h4>Organization</h4>
                                <Organogram userId={userId} reports={reports} users={users} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}