import "./Profiles.css";
import { Avatar, Card, Flex, Text } from "@fluentui/react-northstar";
import { useMediaQuery } from "react-responsive";

import defaultPhoto from '../../images/default-photo.png';

export default function Profiles({ users, setSelectedUser, toggleListingAndProfile, getInfo }) {
    const isMobile = useMediaQuery({ query: "(max-width: 820px)" });

    const usersCards = users?.map((user) => {
        return (
            <Card
                id={user.id}
                aria-roledescription="card avatar"
                centered size="small"
                onClick={async (_, event) => {
                    setSelectedUser(event.id);
                    if (isMobile) {
                        toggleListingAndProfile();
                    };
                    await getInfo(event.id);
                }}
                className='card-dimensions'
            >
                <Card.Header>
                    <Flex gap="gap.smaller" column hAlign="center">
                        <Avatar
                            image={user.profilePhoto ? `data:image/jpeg;base64,${user.profilePhoto}` : defaultPhoto}
                            label=""
                            name=""
                            size="larger"
                        />
                        <Flex column hAlign="center">
                            <Text content={user.displayName} weight="bold" align="center" styles={{ margin: "0px 5px" }} />
                            <Text content={user.jobTitle ? user.jobTitle : "N/A"} size="small" />
                        </Flex>
                    </Flex>
                </Card.Header>
            </Card>
        )
    });

    return (
        <div>
            {isMobile &&
                <div className='listing-grid-mobile'>
                    {usersCards}
                </div>
            }

            {!isMobile &&
                <Flex gap="gap.smaller" wrap="true" hAlign="center">
                    {usersCards}
                </Flex>
            }
        </div>
    )
}