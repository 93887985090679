import "./Tab.css";
import { useContext, useState } from "react";
import { useData } from "@microsoft/teamsfx-react";
import { Loader } from "@fluentui/react-northstar";
import { Buffer } from "buffer";
import { useMediaQuery } from "react-responsive";

import { ErrorBoundary, handleBackendRequestBlockErrors, toasterErrorMessage } from "./utils/errorHandlingUtils";
import { TeamsFxContext } from "./Context";
import { Toaster } from "react-hot-toast";
import Consent from "./helperComponents/Consent";
import { Profile } from "./helperComponents/Profile";
import Listing from "./helperComponents/Listing";
import ManageAdmin from "./appComponents/manageAdmin/ManageAdmin";
import ManageUsersInfo from "./appComponents/manageInfo/ManageUsersInfo";
import { handleChatOpening } from "./utils/generalUtils";
import Modal from "./appComponents/Personal/Modal";
import NavBar from "./helperComponents/NavBar";

export default function Tab({ context, loggedInUser, apiClient, currentHost }) {
  const { themeString } = useContext(TeamsFxContext);
  const [needConsent, setNeedConsent] = useState();
  // const [searchText, setSearchText] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedUser, setSelectedUser] = useState(loggedInUser.objectId);
  const [showListingMobile, setShowListingMobile] = useState(true);
  const [showProfileMobile, setShowProfileMobile] = useState(false);

  const [reports, setReports] = useState([]);
  const [userTimeSettings, setUserTimeSettings] = useState({});
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedNav, setSelectedNav] = useState("Home");

  const isMobile = useMediaQuery({ query: "(max-width: 820px)" });

  // function to show or hide consent page when consent is needed here or down in the component tree
  const triggerConsent = (booleanValue) => {
    setNeedConsent(booleanValue);
  }

  const toggleListingAndProfile = () => {
    setShowListingMobile(!showListingMobile);
    setShowProfileMobile(!showProfileMobile);
    // setSearchText(""); // this is added to reset search results if one has been made prior to viewing profile
  };

  const selectNav = (nav) => {
    setSelectedNav(nav);
  }

  // function to set selected user
  const handleSettingSelectedUser = (user) => {
    setSelectedUser(user);
  }

  // Get logged-in user's access
  const { loading: loadingAccess } = useData(async () => {
    try {
      const response = (await apiClient.get(`/user/access?tenantId=${loggedInUser.tenantId}`)).data.data;
      setIsAdmin(response.isAdmin);
    } catch (err) {
      handleBackendRequestBlockErrors(err);
    }
  });

  // call azure functions consent endpoint to check if there is a need to consent to permissions
  const { loading } = useData(async () => {
    try {
      const response = await apiClient.get("/user/consent");
      if (response.data.data) {
        triggerConsent(false);
      }
    } catch (error) {
      let errorMessage = error.response.data.error;
      if (errorMessage.includes("invalid_grant")) {
        triggerConsent(true);
      } else {
        toasterErrorMessage("An error occured!");
      }
    }
  });

  // get all users data
  const { loading: loadingAllUsersData, reload: reloadAllUsersData } = useData(async () => {
    try {
      const response = await apiClient.get("/user/allUsers?cleanup=full");
      setAllUsers(response.data.data);

      // set departments data
      setDepartments(calcDepartments(response.data.data));

      // get logged-in user's extra information
      await getInfo(loggedInUser.objectId);

      try {
        // get all users with photo
        let responseUsersWithPhotos = await apiClient.get("/user/usersWithPhoto?cleanup=full");

        //convert JSON buffer to base64 image string
        responseUsersWithPhotos = responseUsersWithPhotos.data.data.map((user, index) => {
          if (user.profilePhoto) {
            let photo = Buffer(user.profilePhoto);
            photo = photo.toString("base64");
            user.profilePhoto = photo;
          };
          return user;
        });

        setAllUsers(responseUsersWithPhotos);
      } catch (err) {
        // do nothing when unable to fetch photos (just for now -- Feb. 14th)
      }
    } catch (error) {
      let errorMessage = error.response.data.error;
      if (errorMessage.includes("invalid_grant")) {
        triggerConsent(true);
      } else {
        toasterErrorMessage(errorMessage);
      }
      // handleBackendRequestBlockErrors(error, triggerConsent);
    }
  });

  // set departments
  function calcDepartments(allUsers) {
    let departments = allUsers
      .filter((user) => Boolean(user.department))
      .map((user) => user.department.trim());
    let uniqueDepartments = [...new Set(departments)];
    uniqueDepartments.unshift("All");
    return uniqueDepartments;
  };

  async function getInfo(userId) {
    try {
      // let responseUserTimeSettings = apiClient.get(`/user/userMailboxSettings?userId=${userId}`);
      let responseReportsInfo = apiClient.get(`/user/userReportsInfo?userId=${userId}`);

      // responseUserTimeSettings = (await responseUserTimeSettings).data.data;
      // setUserTimeSettings(responseUserTimeSettings);
      responseReportsInfo = (await responseReportsInfo).data.data;
      setReports(responseReportsInfo);

    } catch (error) {
      let errorMessage = error.response.data.error;
      if (errorMessage.includes("invalid_grant")) {
        triggerConsent(true);
      } else {
        toasterErrorMessage(errorMessage);
      }
      // handleBackendRequestBlockErrors(error, triggerConsent);
    }
  }

  const handleGettingSelectedUserDetailInfo = async (userId) => {
    setReports([]);
    setUserTimeSettings({});
    await getInfo(userId);
  }

  const toggleProfileModal = () => {
    setShowProfileModal(!showProfileModal);
  }

  return (
    <div className={themeString === "default" ? "" : "dark"}>
      <ErrorBoundary>
        {isAdmin && <NavBar selectNav={selectNav} isAdmin={isAdmin} />}

        {selectedNav === "Home" &&
          <div>
            {loading && <Loader className="loading-indicator" />}
            {!loading &&
              <div>
                {
                  needConsent
                    ?
                    <Consent triggerConsent={triggerConsent} reload={reloadAllUsersData} />
                    :
                    <div>
                      <div className="grid-container">
                        {(!isMobile || showListingMobile) &&
                          <Listing
                            allUsers={allUsers}
                            departments={departments}
                            setSelectedUser={handleSettingSelectedUser}
                            toggleListingAndProfile={toggleListingAndProfile}
                            getInfo={handleGettingSelectedUserDetailInfo}
                            isAdmin={isAdmin}
                          />
                        }

                        {(!isMobile || showProfileMobile) &&
                          <div className={`${allUsers.length < 1 ? 'loading-indicator-parent' : ''}`}>
                            {allUsers.length < 1 && <Loader className="loading-indicator" />}
                            <Profile
                              selectedUser={selectedUser}
                              loggedInUserId={loggedInUser.objectId}
                              users={allUsers}
                              toggleListingAndProfile={toggleListingAndProfile}
                              toggleProfileModal={toggleProfileModal}
                              isAdmin={isAdmin}
                              currentHost={currentHost}
                            />
                          </div>
                        }
                      </div>
                    </div>
                }
              </div>
            }

            {showProfileModal === true &&
              <Modal
                reports={reports}
                userId={selectedUser}
                users={allUsers}
                userTimeSettings={userTimeSettings}
                handleChatOpening={handleChatOpening}
                currentHost={currentHost}
                close={() => {
                  toggleProfileModal();
                }}
              />
            }
          </div>
        }

        {selectedNav === "Manage Admin" && !loadingAccess &&
          <ManageAdmin loggedInUser={loggedInUser} isAdmin={isAdmin} loadingAccess={loadingAccess} />
        }

        {selectedNav === "Manage Information" && !loadingAccess &&
          <ManageUsersInfo loggedInUser={loggedInUser} isAdmin={isAdmin} loadingAccess={loadingAccess} />
        }

        <Toaster toastOptions={{ duration: 5000 }} />
      </ErrorBoundary>



    </div>
  );
}
