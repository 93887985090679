import "./ManageAdmin.scss"
// import "reactjs-popup/dist/index.css";
import React, { useState } from 'react';
import { useData } from "@microsoft/teamsfx-react";
// import Popup from "reactjs-popup";
import { Loader } from "@fluentui/react-northstar";
import { AddIcon } from "@fluentui/react-icons-northstar";
import { TeamsFx, BearerTokenAuthProvider, createApiClient } from '@microsoft/teamsfx';

// import iconFunnel from "../../../images/icon-funnel.png";
import EmptyDataPlaceHolder from "../EmptyDataPlaceHolder";
// import menuImage from "../../../images/menu-dots.png";

import AdminForm from "./AdminForm";
import ConfirmAction from "../ConfirmAction";
import { handleBackendRequestBlockErrors, toasterSuccessMessage } from "../../utils/errorHandlingUtils";
import config from "../../utils/config";

import AccessDenied from "../AccessDenied";

// Initialize Azure Functions API Client
function initializeAPIClient() {
    const teamsfx = new TeamsFx();
    const credential = teamsfx.getCredential();
    const apiBaseUrl = config.apiEndpoint + "/api/";
    const apiClient = createApiClient(
        apiBaseUrl,
        new BearerTokenAuthProvider(async () => (await credential.getToken("")).token)
    );
    return apiClient;
}

export default function ManageAdmin({ loggedInUser, isAdmin, loadingAccess }) {
    const [admins, setAdmins] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedAdmin, setSelectedAdmin] = useState({});
    const [isNewForm, setIsNewForm] = useState(true);
    const [showAdminForm, setShowAdminForm] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    const apiClient = initializeAPIClient();
    const confirmDeleteConfig = {
        header: "Delete Admin",
        body: `Are you sure you want to delete admin, ${selectedAdmin?.displayName}?`,
        action: "Delete"
    };

    // Get all admin records
    const { loading: loadingAdminData, reload: reloadAdminData } = useData(async () => {
        try {
            const responseAdmin = (await apiClient.get(`/admin?tenantId=${loggedInUser.tenantId}`)).data.data;
            setAdmins(responseAdmin);
        } catch (err) {
            handleBackendRequestBlockErrors(err);
        }
    });

    // Get all users from Azure AD
    useData(async () => {
        try {
            const responseUsers = await apiClient.get("/user/allUsers?cleanup=mid");
            setUsers(responseUsers.data.data);
        } catch (err) {
            handleBackendRequestBlockErrors(err);
        }
    });

    const handleSubmit = async (event, data) => {
        try {
            event.preventDefault();
            let element = document.getElementById("admin-form-submit");
            element.disabled = true;

            let response;
            let body = {
                mail: data.mail,
                displayName: data.displayName,
                jobTitle: data.jobTitle,
                department: data.department,
                tenantId: loggedInUser.tenantId,
                status: (data.status ? data.status : "Enabled")
            }
            if (isNewForm) {
                response = await apiClient.post("/admin", body);
            } else {
                body.id = data.id;
                response = await apiClient.put("/admin", body);
            }
            toasterSuccessMessage(response.data.message);
        } catch (err) {
            handleBackendRequestBlockErrors(err);
        }

        reloadAdminData();
        setSelectedAdmin({});
        setIsNewForm(true);
        setShowAdminForm(false);
    }


    const handleEditButtonClick = (event) => {
        const id = event.target.parentNode.parentNode.id;
        const admin = admins.find((admin) => parseInt(admin.id) === parseInt(id));

        setSelectedAdmin(admin);
        setIsNewForm(false);
        setShowAdminForm(true);
    };

    const handleDeleteButtonClick = (event) => {
        const id = event.target.parentNode.parentNode.id;
        const admin = admins.find((admin) => parseInt(admin.id) === parseInt(id));

        setSelectedAdmin(admin);
        setShowConfirmDelete(true);
    };

    const handleDelete = async (event) => {
        try {
            event.preventDefault();
            let element = document.getElementById("confirm-action");
            element.disabled = true;

            await apiClient.delete(`/admin?id=${selectedAdmin.id}`);
            toasterSuccessMessage("Successfully deleted the admin.");
        } catch (err) {
            handleBackendRequestBlockErrors(err);
        }

        reloadAdminData();
        setSelectedAdmin({});
        setShowConfirmDelete(false);
    }

    const closeAdminForm = () => {
        setSelectedAdmin({});
        setIsNewForm(true);
        setShowAdminForm(false);
    };

    const closeDeletePopup = () => {
        setSelectedAdmin({});
        setShowConfirmDelete(false);
    };

    return (
        <div className="admin-tab">
            <div>
                {
                    loadingAccess ? (
                        <Loader className="loading-indicator" />
                    ) : (
                        !isAdmin ? (
                            <AccessDenied />
                        ) : (
                            <div className="table-container">
                                <h3 className="manage-admin-config-table-header-text-heading">
                                    Configure Administrators{" "}
                                </h3>

                                <div className="container-btn-new">
                                    <button
                                        className="btn-new"
                                        onClick={() => setShowAdminForm(true)}
                                    >
                                        <AddIcon className="btn-add-icon" /> add new
                                    </button>
                                </div>

                                <div className="manage-admin-config-table-container">
                                    <div className="manage-admin-config-table">
                                        <table className="admin-table">
                                            <thead>
                                                <tr>
                                                    <th>Full Name</th>
                                                    <th>Email</th>
                                                    <th>Job Title</th>
                                                    <th>Department</th>
                                                    <th>Status</th>
                                                    <th className="btn-table-column-admin">
                                                        {" "}
                                                    </th>
                                                </tr>
                                            </thead>
                                            {
                                                loadingAdminData ? (
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={6}>
                                                                <Loader style={{ margin: 100 }} />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                ) : (
                                                    admins.length === 0 ? (
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={6}>
                                                                    <EmptyDataPlaceHolder />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    ) : (
                                                        admins
                                                            .map((item) => {
                                                                return (
                                                                    <tbody>
                                                                        <tr key={item.id} id={item.id}>
                                                                            <td>{item.displayName || "N/A"}</td>
                                                                            <td>{item.mail || "N/A"}</td>
                                                                            <td>{item.jobTitle || "N/A"}</td>
                                                                            <td>{item.department || "N/A"}</td>
                                                                            <td>{item.status || "N/A"}</td>
                                                                            <td>
                                                                                <button
                                                                                    className="btn-table btn-delete"
                                                                                    onClick={handleDeleteButtonClick}
                                                                                >
                                                                                    delete
                                                                                </button>

                                                                                <button
                                                                                    className="btn-table btn-edit"
                                                                                    onClick={handleEditButtonClick}
                                                                                >
                                                                                    edit
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                );
                                                            })
                                                    )
                                                )
                                            }
                                        </table>
                                    </div>
                                </div>

                                {showAdminForm && (
                                    <AdminForm
                                        close={closeAdminForm}
                                        submit={handleSubmit}
                                        isNewForm={isNewForm}
                                        users={users}
                                        selectedAdmin={selectedAdmin}
                                    />
                                )}

                                {showConfirmDelete && (
                                    <ConfirmAction
                                        close={closeDeletePopup}
                                        submit={handleDelete}
                                        config={confirmDeleteConfig}
                                    />
                                )}

                            </div>
                        )
                    )
                }
            </div>

        </div>
    )
}