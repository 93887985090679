import "./TabLanding.css";
import { useContext, useState } from "react";
import { TeamsFxContext } from "./Context";
import { useData } from "@microsoft/teamsfx-react";
import * as microsoftTeams from "@microsoft/teams-js";
import { TeamsFx, TeamsUserCredential } from "@microsoft/teamsfx";
import { BearerTokenAuthProvider, createApiClient } from "@microsoft/teamsfx";
import { Loader } from "@fluentui/react-northstar";
import { useMediaQuery } from "react-responsive";

import { ErrorBoundary } from "./utils/errorHandlingUtils";
import Tab from "./Tab";
import config from "./utils/config";
import appLogo from "./about/images/logo_2_30_x_30.png";
import Consent from "./helperComponents/Consent";

export default function TabLanding() {
    const { themeString } = useContext(TeamsFxContext);
    const [loggedInUser, setLoggedInUser] = useState({});
    const [context, setContext] = useState({});

    const [isTeamsInitialConsentCancelled, setIsTeamsInitialConsentCancelled] = useState(undefined);
    const [hasSubscription, setHasSubscription] = useState(undefined);
    const [subsriptionErrorDetail, setSubsriptionErrorDetail] = useState({
        header: "",
        body: "",
        active: undefined
    });
    const [errorOccured, setErrorOccured] = useState(false);
    const [needConsent, setNeedConsent] = useState();
    const [showBuyNowButton, setShowBuyNowButton] = useState(true);
    const [showProceedButton, setShowProceedButton] = useState(false);
    const [currentHost, setCurrentHost] = useState();

    const isMobile = useMediaQuery({ query: "(max-width: 450px)" });

    // stop native loading indicator defined in manifest when app loads
    useData(async () => {
        try {
            await microsoftTeams.app.initialize();
            const context = await microsoftTeams.app.getContext();
            setContext(context);

            if (
                Object.values(microsoftTeams.HostName).includes(context.app.host.name)
            ) {
                setCurrentHost(context.app.host.name);
                microsoftTeams.app.notifySuccess();
            }
        } catch (error) {
            microsoftTeams.app.notifyFailure({
                reason: microsoftTeams.app.FailedReason.Timeout,
                message: error,
            });
        }
    });

    // code block doubles as setting logged-in user detail and checking for irregularity with user's tenant
    const { loading, reload } = useData(async () => {
        try {
            const authConfig = {
                clientId: process.env.REACT_APP_CLIENT_ID,
                initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL,
            };
            const credential = new TeamsUserCredential(authConfig);
            const userData = await credential.getUserInfo();
            setLoggedInUser(userData);
            setErrorOccured(false);
            await checkSubscriptionStatus(userData.objectId);
        } catch (err) {
            setErrorOccured(true);
        }
    });

    // Create API client
    const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;
    if (!teamsUserCredential) {
        // TODO: Replace this with a toaster error popup.
        throw new Error("TeamsFx SDK is not initialized.");
    }
    const apiBaseUrl = config.apiEndpoint + "/api/";
    const apiClient = createApiClient(
        apiBaseUrl,
        new BearerTokenAuthProvider(async () => (await teamsUserCredential.getToken("")).token)
    );

    // function to show or hide consent page when consent is needed here or down in the component tree
    const triggerConsent = (booleanValue) => {
        setNeedConsent(booleanValue);
    }

    // check logged-in user's subscription status
    const checkSubscriptionStatus = async (userId) => {
        try {
            let response;
            try {
                response = await apiClient.get(`/user/subscriptionStatus?userId=${userId}`);
            } catch (err) {
                let error = {
                    status: err?.response?.status || 500,
                    message: err?.response?.data?.error || 'Unknown'
                };
                throw error;
            }
            // retrieve data if request is successful
            const userSubscriptionInfo = response.data.data;

            if (userSubscriptionInfo.hasSubscription && userSubscriptionInfo.isOnTrial === false) {
                setHasSubscription(true);
            } else if (userSubscriptionInfo.hasSubscription && userSubscriptionInfo.isOnTrial === true) {
                if (userSubscriptionInfo.remainingFreeTrialDays > 1) {
                    setHasSubscription(true);
                }
                else if (userSubscriptionInfo.remainingFreeTrialDays === 1) {
                    setHasSubscription(false);
                    setSubsriptionErrorDetail({
                        header: "Free trial ends today!",
                        body: "Your free trial for the Employee Lookup application subscription will be ending today. Please 'Buy Now' to a subscription or contact your admin to take necessary action.",
                        active: true
                    });
                } else {
                    setHasSubscription(false);
                    setSubsriptionErrorDetail({
                        header: "No subscription!",
                        body: "You have no subscription to use the Employee Lookup application. Please use free trial or buy a subscription by clicking the 'Buy Now' button below."
                    });
                }
            } else {
                setHasSubscription(false);
                setShowProceedButton(false);
                setSubsriptionErrorDetail({
                    header: "No subscription!",
                    body: "You have no subscription to use the Employee Lookup application. Please use free trial or buy a subscription by clicking the 'Buy Now' button below."
                });
            }
        } catch (err) {
            if (err.message.includes("invalid_grant")) {
                triggerConsent(true);
            } else if (err.message?.includes("CancelledByUser")) {
                setIsTeamsInitialConsentCancelled(true);
            } else if (err.message?.includes("Specified user does not exist")) {
                // this categorization is so to avoid showing the generic error (when the backend responds with an error) when a user without subscription adds the app to their Teams and instead show them they have no subscription
                setHasSubscription(false);
                setSubsriptionErrorDetail({
                    header: "No subscription!",
                    body: "You have no subscription to use the Employee Lookup application. Please use free trial or buy a subscription by clicking the 'Buy Now' button below."
                });
            } else {
                setErrorOccured(true);
            }
        }
    };

    const cancelSubscriptionWarning = () => {
        setHasSubscription(true);
    };

    const handlePurchaseDialog = () => {
        // This method will invoke the in-purchase window
        let planInfo = {
            planId: "Enterprise", // Plan Id of the published SAAS Offer
            term: "monthly" // Term of the plan.
        }
        microsoftTeams.monetization.openPurchaseExperience(planInfo);
    };

    return (
        <div className={themeString === "default" ? "" : "dark"}>
            <ErrorBoundary>
                {needConsent &&
                    <div>
                        <Consent triggerConsent={triggerConsent} reload={reload} />
                    </div>
                }

                {!needConsent && errorOccured &&
                    <div className='install-error-wrapper'>
                        <div className='install-error'>
                            <div><img src={appLogo} alt='Employee Lookup Logo' className='logo' /></div>
                            <p className='install-error-head'>An Error Occured!</p>
                            <p className='install-error-body'>Please reload the application. If the issue persists, contact your admin or reach out to the Employee Lookup application support team at <span className='landing-contact'>be@relianceinfosystems.com</span></p>
                        </div>
                    </div>
                }

                {!needConsent && !errorOccured &&
                    <div>
                        {hasSubscription === undefined && isTeamsInitialConsentCancelled !== true &&
                            <Loader style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }} />
                        }

                        {!loading && isTeamsInitialConsentCancelled !== true && hasSubscription === true && <div>
                            <Tab
                                context={context}
                                loggedInUser={loggedInUser}
                                apiClient={apiClient}
                                currentHost={currentHost}
                            />
                        </div>}

                        {isTeamsInitialConsentCancelled === true &&
                            <div className='initial-consent-cancel-wrapper'>
                                <div className='initial-consent-cancel'>
                                    <div><img src={appLogo} alt='Employee Lookup Logo' className='logo' /></div>
                                    <p className='initial-consent-cancel-head'>Initial consent cancelled.</p>
                                    <p>You are seeing this page because you cancelled the consent flow. Please reload the application and grant consent.</p>
                                </div>
                            </div>
                        }

                        {hasSubscription === false &&
                            <div className='initial-consent-cancel-wrapper'>
                                <div className='initial-consent-cancel'>
                                    {loading &&
                                        <Loader
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)'
                                            }}
                                        />
                                    }
                                    <div><img src={appLogo} alt='Employee Lookup Logo' className='logo' /></div>
                                    <p className='initial-consent-cancel-head'>{subsriptionErrorDetail.header}</p>
                                    <p>
                                        {subsriptionErrorDetail.body}
                                        {subsriptionErrorDetail.header !== "Proceed!" ? " Or reach out to the Employee Lookup application support team at " : ""}
                                        {subsriptionErrorDetail.header !== "Proceed!" && currentHost !== "Office" && <a className='landing-contact' href="mailto:be@relianceinfosystems.com?subject=Employee Lookup Subscription">be@relianceinfosystems.com</a>}
                                        {subsriptionErrorDetail.header !== "Proceed!" && currentHost === "Office" && <span>be@relianceinfosystems.com</span>}
                                    </p>


                                    <div className='subscription-button-container'>
                                        {showBuyNowButton &&
                                            <button
                                                className='subscription-button'
                                                onClick={() => {
                                                    if (currentHost === "Teams" && !isMobile) {
                                                        handlePurchaseDialog();

                                                        // solution to check subscription status again after initial interraction
                                                        setTimeout(() => {
                                                            setShowProceedButton(true);
                                                            setSubsriptionErrorDetail({
                                                                header: "Proceed!",
                                                                body: "If you just purchased a subscription, please click the 'Proceed' button below. If you're unable to proceed, please check back later (to allow the purchase action to sync) and reload the application. Or 'Buy Now' if no purchase has been made."
                                                            });
                                                        }, 4000);
                                                    } else {
                                                        setSubsriptionErrorDetail({
                                                            header: "Kindly Use the Microsoft Teams Desktop Client!",
                                                            body: "To buy a subscription, kindly open the Microsoft Teams Desktop Client, open the Employee Lookup application and make a purchase."
                                                        });
                                                        setShowBuyNowButton(false);
                                                    }
                                                }}
                                            >
                                                Buy Now
                                            </button>
                                        }

                                        {showProceedButton &&
                                            <button
                                                className='subscription-button'
                                                onClick={() => {
                                                    reload();
                                                }}
                                            >
                                                Proceed
                                            </button>
                                        }

                                        {subsriptionErrorDetail.active &&
                                            <button
                                                className='subscription-button'
                                                onClick={() => {
                                                    cancelSubscriptionWarning();
                                                }}
                                            >
                                                Continue
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
            </ErrorBoundary>
        </div>
    );
}