import "./AdminForm.scss";
import React, { useState } from "react";

import btn_Cancel from "../../../images/cancel.png";

export default function AdminForm({ isNewForm, users, selectedAdmin, close, submit }) {
    const [admin, setAdmin] = useState(selectedAdmin || {});

    return (
        <div className="edit-container">
            <form
                className="edit-container-form"
                onSubmit={(event) => {
                    submit(event, admin);
                }}
            >
                <div className="edit-container-form-header">
                    <h4>{isNewForm ? "Add Admin" : "Update Admin"}</h4>
                    <img
                        src={btn_Cancel}
                        alt="cancel"
                        onClick={close}
                    />
                </div>

                <div className="edit-container-form-group">
                    <div>
                        <label htmlFor="leave-type">Full Name:</label>
                        <select
                            name="leave type"
                            id="leave-type"
                            value={admin.mail}
                            onChange={(event) => {
                                const selectedUser = users.find((user) => user.mail === event.target.value);
                                setAdmin(
                                    {
                                        ...admin,
                                        displayName: selectedUser.displayName,
                                        mail: selectedUser.mail,
                                        jobTitle: selectedUser.jobTitle,
                                        department: selectedUser.department,
                                    }
                                );
                            }}
                        >
                            <option value="" disabled selected className="selected">
                                Please pick an employee
                            </option>
                            {users.map((user) => {
                                return (
                                    <option value={user.mail} key={user.id}>
                                        {user.displayName}
                                    </option>
                                );
                            })}
                        </select>
                    </div>

                    <div>
                        <label htmlFor="total-days">
                            Email:
                        </label>
                        <input
                            type="text"
                            placeholder="phillipmorrie@relianceinfosystems.com"
                            value={admin.mail || "N/A"}
                            disabled
                        />
                    </div>

                    <div>
                        <label htmlFor="total-days">
                            Job Title:
                        </label>
                        <input
                            type="text"
                            placeholder="Operations Manager"
                            value={admin.jobTitle || "N/A"}
                            disabled
                        />
                    </div>

                    <div>
                        <label htmlFor="total-days">
                            Department:
                        </label>
                        <input
                            type="text"
                            placeholder="Operations"
                            value={admin.department || "N/A"}
                            disabled
                        />
                    </div>

                    {!isNewForm &&
                        <div>
                            <label htmlFor="leave-type">Status:</label>
                            <select
                                name="leave type"
                                id="leave-type"
                                value={admin.status}
                                onChange={(event) => setAdmin({ ...admin, status: event.target.value })}
                            >
                                <option value="" disabled selected className="selected">
                                    Please pick a status
                                </option>
                                <option value="Enabled">
                                    Enabled
                                </option>
                                <option value="Disabled">
                                    Disabled
                                </option>
                            </select>
                        </div>
                    }

                    <div className="edit-form-buttons-admin">
                        <button
                            type="button"
                            onClick={close}
                            className="edit-btn-admin edit-btn-cancel-admin"
                        >
                            Close
                        </button>

                        <button
                            type="submit"
                            className="edit-btn-admin edit-btn-submit-admin"
                            id="admin-form-submit"
                        >
                            {isNewForm ? "Submit" : "Update"}
                        </button>
                    </div>

                </div>
            </form>
        </div>
    )
}