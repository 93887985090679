import "./Profile.css";
import * as microsoftTeams from '@microsoft/teams-js';
import { AppFolderIcon, BriefcaseIcon, CallDialpadIcon, CallIcon, ChatIcon, ChevronStartIcon, EmailIcon, Flex, Text } from "@fluentui/react-northstar";
import { useMediaQuery } from "react-responsive";

import defaultPhoto from '../../images/default-photo.png';
import { handleChatOpening, handleAudioCall } from "../utils/generalUtils";

export function Profile({ selectedUser, loggedInUserId, users, toggleListingAndProfile, toggleProfileModal, isAdmin, currentHost }) {
    const user = users.find((user) => user.id === selectedUser);
    const isMobile = useMediaQuery({ query: "(max-width: 820px)" });

    return (
        <div className={`
            profile-display 
            ${selectedUser === loggedInUserId || microsoftTeams.chat.isSupported() === false ? "profile-display-reduce-header" : ""}
            ${isAdmin ? "profile-display-admin-view" : ""}
        `}>
            <div className="profile-display-header">
                {isMobile &&
                    <div className='buttons-container'>
                        <button className='display-back-button' onClick={toggleListingAndProfile}><ChevronStartIcon />Back</button>
                        {microsoftTeams.chat.isSupported() === true && selectedUser !== loggedInUserId &&
                            <div className='contact-button'>
                                {/* {
                                    currentHost === "Teams" &&
                                    <button
                                        className='comm-btn'
                                        onClick={() => handleChatOpening(user)}
                                    >
                                        <ChatIcon className='btn-icon' /><span className='btn-span'></span>Chat
                                    </button>
                                } */}
                                <button
                                    className='comm-btn'
                                    onClick={() => handleAudioCall(user)}
                                >
                                    <CallIcon className='btn-icon' /><span className='btn-span'></span>Call
                                </button>
                            </div>
                        }
                    </div>
                }

                {!isMobile && microsoftTeams.chat.isSupported() === true && selectedUser !== loggedInUserId &&
                    <div className='contact-button'>
                        {
                            currentHost === "Teams" &&
                            <button
                                className='comm-btn'
                                onClick={() => handleChatOpening(user)}
                            >
                                <ChatIcon className='btn-icon' /><span className='btn-span'></span>Chat
                            </button>
                        }
                        <button
                            className='comm-btn'
                            onClick={() => handleAudioCall(user)}
                        >
                            <CallIcon className='btn-icon' /><span className='btn-span'></span>Call
                        </button>
                    </div>
                }
            </div>
            <div className="profile-display-body-container">
                <div className="profile-display-body">
                    {user &&
                        <div className="display-profile">
                            <Flex column hAlign="center">
                                <div className="photo">
                                    <img src={user.profilePhoto ? `data:image/jpeg;base64,${user.profilePhoto}` : defaultPhoto} alt="avatar" />
                                </div>
                                <Text content={user.displayName ? user.displayName : "N/A"} weight="bold" size="large" />
                            </Flex>

                            <Flex column gap="gap.large" className="profile-body" hAlign="start">
                                <p style={{ margin: "10px 5px" }} ><EmailIcon /><span></span>{user.mail ? user.mail : "N/A"}</p>
                                <p style={{ margin: "10px 5px" }} ><BriefcaseIcon /><span></span>{user.jobTitle ? user.jobTitle : "N/A"}</p>
                                <p style={{ margin: "10px 5px" }} ><AppFolderIcon /><span></span>{user.department ? user.department : "N/A"}</p>
                                <p style={{ margin: "10px 5px" }} ><CallIcon /><span></span>{user.mobilePhone ? user.mobilePhone : "N/A"}</p>
                                <p style={{ margin: "10px 5px" }} ><CallDialpadIcon /><span></span>{user?.businessPhones?.join(", ") ? user?.businessPhones?.join(", ") : "N/A"}</p>
                                <p
                                    style={{ margin: "10px 10px" }}
                                >
                                    <button
                                        className='comm-btn'
                                        style={{ padding: '5px', margin: '0px' }}
                                        onClick={() => {
                                            toggleProfileModal();
                                        }}
                                    >
                                        Show more
                                    </button>
                                </p>
                            </Flex>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}