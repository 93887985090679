import * as microsoftTeams from '@microsoft/teams-js';
// import { collection, addDoc } from "firebase/firestore";
// import { db } from '../../firebase';

// Function : Handle opening of Microsoft Teams chat window
const handleChatOpening = async (user) => {
    const chatParams = {
        user: user.userPrincipalName || '',
    };
    await microsoftTeams.chat.openChat(chatParams);
}

// Function : Handle opening of Microsoft Teams call window
const handleAudioCall = async (user) => {
    const callParams = {
        targets: [`${user.userPrincipalName}`],
    };
    await microsoftTeams.call.startCall(callParams);
}

// write to firebase
// const addError = async (error, metaData) => {
//     try {
//         const docRef = await addDoc(collection(db, "e-lookup-errors"), {
//             error,
//             metaData,
//             date: new Date().toLocaleDateString("sv-SE"),
//             time: new Date().toLocaleTimeString("sv-SE")
//         });
//         console.log("Document written with ID: ", docRef.id);
//     } catch (e) {
//         console.error("Error adding document: ", e);
//     }
// }

export {
    handleChatOpening,
    handleAudioCall,
    // addError
}