import "./UserInfoForm.scss";
import React, { useState } from "react";

import btn_Cancel from "../../../images/cancel.png";

export default function UserInfoForm({ isEditForm, selectedUser, close, submit }) {
    const [user, setUser] = useState(selectedUser || {});

    let placeholderText = isEditForm ? "" : "N/A";

    const handleUserPropertyChange = (event) => {
        let property = event.target.name;
        let value = event.target.value;
        setUser({ ...user, [property]: value });
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        let element = document.getElementById("admin-form-submit");
        element.disabled = true;

        let updatedUserProperties = {};
        Object.keys(user).forEach((property) => {
            if (selectedUser[property] !== user[property]) {
                updatedUserProperties[property] = user[property];
            }
        });
        if (isEditForm) {
            submit(updatedUserProperties);
        } else {
            submit();
        }
    }

    return (
        <div className="edit-container">
            <form
                className="edit-container-form"
                onSubmit={handleFormSubmit}
            >
                <div className="edit-container-form-header">
                    <h4>User Data</h4>
                    <img
                        src={btn_Cancel}
                        alt="cancel"
                        onClick={close}
                    />
                </div>

                <div className="edit-container-form-group">
                    <div>
                        <label htmlFor="leave-type">Full Name:</label>
                        <input
                            type="text"
                            placeholder="Phillip Morrie"
                            value={user.displayName || "N/A"}
                            disabled
                        />
                    </div>

                    <div>
                        <label htmlFor="total-days">
                            Email:
                        </label>
                        <input
                            type="text"
                            placeholder="phillipmorrie@relianceinfosystems.com"
                            value={user.mail || "N/A"}
                            disabled
                        />
                    </div>

                    <div>
                        <label htmlFor="total-days">
                            Job Title:
                        </label>
                        <input
                            type="text"
                            name="jobTitle"
                            placeholder="E.g. Operations Manager"
                            value={user.jobTitle || placeholderText}
                            disabled={!isEditForm}
                            onChange={handleUserPropertyChange}
                        />
                    </div>

                    <div>
                        <label htmlFor="total-days">
                            Department:
                        </label>
                        <input
                            type="text"
                            name="department"
                            placeholder="E.g. Operations"
                            value={user.department || placeholderText}
                            disabled={!isEditForm}
                            onChange={handleUserPropertyChange}
                        />
                    </div>

                    <div>
                        <label htmlFor="total-days">
                            Mobile Phone:
                        </label>
                        <input
                            type="text"
                            placeholder="Operations"
                            value={user.mobilePhone || "N/A"}
                            disabled
                        />
                    </div>

                    <div>
                        <label htmlFor="total-days">
                            Office Location:
                        </label>
                        <input
                            type="text"
                            name="officeLocation"
                            placeholder="E.g. London, United Kingdom"
                            value={user.officeLocation || placeholderText}
                            disabled={!isEditForm}
                            onChange={handleUserPropertyChange}
                        />
                    </div>

                    <div>
                        <label htmlFor="leave-type">Employee Type:</label>
                        <select
                            id="leave-type"
                            name="employeeType"
                            value={user.employeeType}
                            disabled={!isEditForm}
                            onChange={handleUserPropertyChange}
                        >
                            <option value="" disabled selected className="selected">
                                Please pick an employee type
                            </option>
                            <option value="Employee">
                                Employee
                            </option>
                            <option value="Contractor">
                                Contractor
                            </option>
                            <option value="Consultant">
                                Consultant
                            </option>
                            <option value="Vendor">
                                Vendor
                            </option>
                            <option value="Service account">
                                Service account
                            </option>
                        </select>
                    </div>

                    <div className={`edit-form-buttons-user-info ${!isEditForm ? "view-form-button" : ""}`}>
                        <button
                            type="button"
                            onClick={close}
                            className={`edit-btn-user-info ${!isEditForm ? "edit-btn-submit-user-info" : "edit-btn-cancel-user-info"}`}
                        >
                            Close
                        </button>

                        {isEditForm &&
                            <button
                                type="submit"
                                className="edit-btn-user-info edit-btn-submit-user-info"
                                id="admin-form-submit"
                            >
                                Save
                            </button>
                        }
                    </div>

                </div>
            </form>
        </div>
    )
}