import "./EmptyDataPlaceHolder.scss";
import React from "react";
import no_Data_Image from "../../images/empty-data-image.jpg";

function EmptyDataPlaceHolder() {
  return (
    <div className="no-data-container">
      <div className="no-data-container-img">
        <img src={no_Data_Image} alt="" />
      </div>

      <div className="no-data-container-text">
         <p>No data available yet!</p>
      </div>
    </div>
  );
}

export default EmptyDataPlaceHolder;
