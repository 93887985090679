import { Avatar } from "@fluentui/react-northstar";


export default function UsersListing({
    users,
    toggleRowCheckbox,
    selectedRows,
    handleViewUserButtonClick,
    handleEditUserButtonClick,
    handleSendSingleEmailButtonClick
}) {

    return (
        <tbody>
            {
                users.map((item) => {
                    return (
                        <tr key={item.id} id={item.id}>
                            <th>
                                <input
                                    type="checkbox"
                                    name=""
                                    id="all"
                                    checked={selectedRows.includes(item.id)}
                                    onChange={() => toggleRowCheckbox(item.id)}
                                />
                            </th>
                            <td>
                                <Avatar
                                    name={item.displayName}
                                    image={item.photo ? `data:image/jpeg;base64,${item.photo}` : ""}
                                />
                            </td>
                            <td>{item.displayName}</td>
                            <td>{item.mail ? item.mail : "N/A"}</td>
                            <td>{item.jobTitle ? item.jobTitle : "N/A"}</td>
                            <td>{item.department ? item.department : "N/A"}</td>
                            <td>{item.mobilePhone ? item.mobilePhone : "N/A"}</td>
                            <td>
                                <button
                                    className="btn-table btn-delete"
                                    onClick={() => handleSendSingleEmailButtonClick(item)}
                                >
                                    Email
                                </button>

                                <button
                                    className="btn-table btn-edit btn-delete"
                                    onClick={() => handleEditUserButtonClick(item)}
                                >
                                    Edit
                                </button>

                                <button
                                    className="btn-table btn-edit"
                                    onClick={() => handleViewUserButtonClick(item)}
                                >
                                    View
                                </button>
                            </td>
                        </tr>
                    );
                })
            }
        </tbody>
    )
};