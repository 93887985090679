import "./ManageUsersInfo.scss";
// import "reactjs-popup/dist/index.css";
import React, { useState } from 'react';
import { useData } from "@microsoft/teamsfx-react";
// import Popup from "reactjs-popup";
import { Input, Loader, SearchIcon } from "@fluentui/react-northstar";
import { useMediaQuery } from "react-responsive";


// import iconFunnel from "../../../images/icon-funnel.png";
import EmptyDataPlaceHolder from "../EmptyDataPlaceHolder";
// import menuImage from "../../../images/menu-dots.png";


import ConfirmAction from "../ConfirmAction";
import UserInfoForm from "./UserInfoForm";
import UsersListing from "./UsersListing";



import { TeamsFx, BearerTokenAuthProvider, createApiClient } from '@microsoft/teamsfx';
import { handleBackendRequestBlockErrors, toasterSuccessMessage, toasterErrorMessage } from "../../utils/errorHandlingUtils";

// import config from '../../../lib/config';
import config from "../../utils/config";

import AccessDenied from "../AccessDenied";
import DesktopOnly from "../DesktopOnly";
window.Buffer = window.Buffer || require("buffer").Buffer; // this is like a polyfill for the Buffer class

// Initialize Azure Functions API Client
function initializeAPIClient() {
    const teamsfx = new TeamsFx();
    const credential = teamsfx.getCredential();
    const apiBaseUrl = config.apiEndpoint + "/api/";
    const apiClient = createApiClient(
        apiBaseUrl,
        new BearerTokenAuthProvider(async () => (await credential.getToken("")).token)
    );
    return apiClient;
}


export default function ManageUsersInfo({ isAdmin, loadingAccess }) {
    const [users, setUsers] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [isBulkEmail, setIsBulkEmail] = useState(false);
    const [isDisabledBulkEmailButton, setIsDisabledBulkEmailButton] = useState(true);
    const [showSendEmail, setShowSendEmail] = useState(false);
    const [showUserInfoForm, setShowUserInfoForm] = useState(false);
    const [isEditForm, setIsEditForm] = useState(false);

    const isDesktop = useMediaQuery({ query: "(min-width: 450px)" });

    const apiClient = initializeAPIClient();
    const confirmSendEmailConfig = {
        header: isBulkEmail ? "Send Bulk Email" : "Send Email",
        body: `Are you sure you want to send profile update request email to ${selectedRows.length === 1 ? selectedUser.displayName : ""}${selectedRows.length > 1 ? "selected users (only those with missing data)" : ""}?`,
        action: "Send"
    }

    // Get all users from Azure AD
    const { loading: loadingUsers, reload: reloadUsers } = useData(async () => {
        try {
            const responseUsers = await apiClient.get("/user/allUsers");
            setUsers(responseUsers.data.data);
        } catch (err) {
            handleBackendRequestBlockErrors(err);
        }
    });

    const { reload: reloadUsersWithPhotos } = useData(async () => {
        try {
            // get and update users' profile photo
            let responseUsersWithPhoto = await apiClient.get("/user/usersWithPhoto");
            //convert JSON buffer to base64 image string
            responseUsersWithPhoto = responseUsersWithPhoto.data.data.map((user) => {
                if (user.profilePhoto) {
                    let photo = window.Buffer(user.profilePhoto);
                    photo = photo.toString("base64");
                    user.photo = photo;
                };
                return user;
            });
            setUsers(responseUsersWithPhoto);
        } catch (err) {
            // do nothing when unable to fetch photos (just for now -- Mar. 6th)
            // handleBackendRequestBlockErrors(err);
        }
    })


    // useEffect(() => {
    //     console.log("....................... reached here yo")
    //     if (users.length > 0) {
    //         (async () => {
    //             // get and update users' profile photo
    //             let responseUsersWithPhoto = await apiClient.get("/user/usersWithPhoto");
    //             //convert JSON buffer to base64 image string
    //             responseUsersWithPhoto = responseUsersWithPhoto.data.data.map((user) => {
    //                 if (user.profilePhoto) {
    //                     let photo = window.Buffer(user.profilePhoto);
    //                     photo = photo.toString("base64");
    //                     user.photo = photo;
    //                 };
    //                 return user;
    //             });
    //             setUsers(responseUsersWithPhoto);
    //         })();
    //         console.log(".......................... yo...")
    //     }
    // }, [users]);

    // functions to toggle checkboxes
    const toggleSelectAll = () => {
        setSelectAll(!selectAll);
        if (!selectAll) {
            setSelectedRows(users.map(row => row.id));
            setIsDisabledBulkEmailButton(false);
        } else {
            setIsDisabledBulkEmailButton(true);
            setSelectedRows([]);
        }
    };

    const toggleRowCheckbox = (id) => {
        if (selectedRows.includes(id)) {
            setSelectedRows(selectedRows.filter(rowId => rowId !== id));
            if (selectedRows.length <= 2) {
                setIsDisabledBulkEmailButton(true);
            }
        } else {
            setSelectedRows([...selectedRows, id]);
            if (selectedRows.length > 0) {
                setIsDisabledBulkEmailButton(false);
            }
        }
        setSelectAll(false);
    };


    // functions to handle bulk email buttons
    const handleSendBulkEmailButtonClick = (event) => {

        setIsBulkEmail(true);
        setShowSendEmail(true);
    };

    const closeSendBulkEmailPopup = () => {

        setIsBulkEmail(false);
        setShowSendEmail(false);
    };


    // functions to handle single email buttons
    const handleSendSingleEmailButtonClick = (item) => {
        // uncheck all other checkboxes except that of selected row and disable send bulk email button
        setSelectAll(false);
        setSelectedRows([item.id]);
        setIsDisabledBulkEmailButton(true);

        setSelectedUser(item);
        setIsBulkEmail(false);
        setShowSendEmail(true);
    };

    const closeSendSingleEmailPopup = () => {

        setSelectedRows([]);
        setShowSendEmail(false);
    };


    // functions to handle view user details buttons
    const handleViewUserButtonClick = (item) => {
        // uncheck all other checkboxes except that of selected row and disable send bulk email button
        setSelectAll(false);
        setSelectedRows([item.id]);
        setIsDisabledBulkEmailButton(true);

        setSelectedUser(item);
        setShowUserInfoForm(true);
    }

    const handleEditUserButtonClick = (item) => {
        // uncheck all other checkboxes except that of selected row and disable send bulk email button
        setSelectAll(false);
        setSelectedRows([item.id]);
        setIsDisabledBulkEmailButton(true);

        setIsEditForm(true);
        setSelectedUser(item);
        setShowUserInfoForm(true);
    }

    const closeUserInfoForm = () => {
        setIsEditForm(false);
        setSelectedRows([]);
        setShowUserInfoForm(false);
    }


    const handleSendEmailSubmit = async (event) => {
        try {
            let element;
            let changedSelectedRows = [];

            event.preventDefault();
            element = document.getElementById("confirm-action");
            element.disabled = true;

            const selectedUsersData = users.filter(row => selectedRows.includes(row.id));
            const selectedUsersWithMissingProperty = selectedUsersData.filter(user => {
                // remove users without email address
                if (!user.mail) {
                    setSelectAll(false); // uncheck select all when a row is removed
                    return false;
                }

                if (!user.photo || !user.jobTitle || !user.department || !user.mobilePhone) {
                    changedSelectedRows.push(user.id);
                    return true;
                }
                setSelectAll(false); // uncheck select all when there is a row without missing data
                return false;
            });

            if (selectedUsersWithMissingProperty.length < 1) {
                toasterErrorMessage("Selected user(s) does not have missing data (photo, job title, department, mobile phone)")
                return;
            } else if (selectedUsersWithMissingProperty.length === 1) {
                setSelectedUser(selectedUsersWithMissingProperty[0]);
            }

            setSelectedRows(changedSelectedRows); // reset checked rows to only those with missing data

            const response = await apiClient.post("/mail/sendMail", selectedUsersWithMissingProperty);
            toasterSuccessMessage(response.data.message);
        } catch (err) {
            handleBackendRequestBlockErrors(err);
        }

        setSelectedUser({});
        setSelectedRows([]);
        setIsDisabledBulkEmailButton(true);
        setShowSendEmail(false);
    }

    const handleUserFormSubmit = async (updatedUserProperties) => {
        try {
            updatedUserProperties.id = selectedUser.id;
            const response = await apiClient.put("/user/updateUser", updatedUserProperties);
            toasterSuccessMessage(response.data.message);
        } catch (err) {
            handleBackendRequestBlockErrors(err);
        }

        setSelectedUser({});
        setSelectedRows([]);
        setIsEditForm(false);
        setShowUserInfoForm(false);
        reloadUsers();
        reloadUsersWithPhotos();
    }

    // function to handle searching users
    const handleUsersListFiltering = () => {
        if (searchText) {
            let searchedUsersData = users.filter((user) => {
                return user.displayName.toLowerCase().includes(searchText.toLowerCase());
            });
            return searchedUsersData;
        } else {
            return users;
        }
    };

    return (
        <div className="admin-tab">
                <div>
                    {
                        loadingAccess ? (
                            <Loader className="loading-indicator" />
                        ) : (
                            !isAdmin ? (
                                <AccessDenied />
                            ) : (
                                <div className="table-container">
                                    <div className="manage-info-config-table-header">
                                        <h3 className="manage-info-config-table-header-text-heading">
                                            User Data Management{" "}
                                        </h3>
                                        <div className='manage-admin-search'>
                                            <Input
                                                icon={<SearchIcon />}
                                                placeholder="Search Employee..."
                                                fluid
                                                onChange={(_, event) => {
                                                    setSearchText(event.value);
                                                }}
                                            // {...propsForInput}
                                            // input={propsTargettingInput}
                                            />
                                        </div>
                                    </div>

                                    <div className="manage-admin-config-table-container">
                                        <div className="manage-admin-config-table">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <input
                                                                type="checkbox"
                                                                name=""
                                                                id="all"
                                                                checked={selectAll}
                                                                onChange={toggleSelectAll}
                                                            />
                                                        </th>
                                                        <th>Photo</th>
                                                        <th>Full Name</th>
                                                        <th>Email</th>
                                                        <th>Job Title</th>
                                                        <th>Department</th>
                                                        <th>Phone</th>
                                                        <th>
                                                            {" "}
                                                            <button
                                                                className="btn-table send-bulk-email btn-table-column-user"
                                                                disabled={isDisabledBulkEmailButton}
                                                                onClick={handleSendBulkEmailButtonClick}
                                                            >
                                                                Send Bulk Email
                                                            </button>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                {
                                                    loadingUsers ? (
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={8}>
                                                                    <Loader style={{ margin: 100 }} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    ) : (
                                                        users.length === 0 ? (
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={6}>
                                                                        <EmptyDataPlaceHolder />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        ) : (
                                                            <UsersListing
                                                                users={handleUsersListFiltering()}
                                                                toggleRowCheckbox={toggleRowCheckbox}
                                                                selectedRows={selectedRows}
                                                                handleViewUserButtonClick={handleViewUserButtonClick}
                                                                handleEditUserButtonClick={handleEditUserButtonClick}
                                                                handleSendSingleEmailButtonClick={handleSendSingleEmailButtonClick}
                                                            />
                                                        )
                                                    )
                                                }
                                            </table>
                                        </div>
                                    </div>

                                    {showUserInfoForm && (
                                        <UserInfoForm
                                            isEditForm={isEditForm}
                                            close={closeUserInfoForm}
                                            submit={handleUserFormSubmit}
                                            selectedUser={selectedUser}
                                        />
                                    )}

                                    {showSendEmail && (
                                        <ConfirmAction
                                            close={isBulkEmail ? closeSendBulkEmailPopup : closeSendSingleEmailPopup}
                                            submit={handleSendEmailSubmit}
                                            config={confirmSendEmailConfig}
                                        />
                                    )}

                                </div>
                            )
                        )
                    }
                </div>
        </div>
    )
}