import "./ProfileListings.css";
import Profiles from "./Profiles";

export default function ProfileListings({ allUsers, filteredUsers, searchedUsers, searchText, setSelectedUser, toggleListingAndProfile, getInfo }) {
    const usersList = () => {
        if (searchText === "" && filteredUsers.length === 0) {
            return allUsers;
        } else if (searchText === "" && filteredUsers.length > 0) {
            return filteredUsers;
        } else {
            return searchedUsers;
        }
    };
    return (
        <div className='profile-cards-container'>
            <Profiles
                users={usersList()}
                setSelectedUser={setSelectedUser}
                toggleListingAndProfile={toggleListingAndProfile}
                getInfo={getInfo}
            />
        </div>
    )
}