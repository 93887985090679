import "./ListingHeader.css";
import { Dropdown, FilterIcon, Input, SearchIcon } from "@fluentui/react-northstar";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";

export default function ListingHeader({ departments, filterUsers, searchUsers }) {
    const [selectedDepartment, setSelectedDepartment] = useState("");
    const [searchText, setSearchText] = useState("");
    const [showMobileDepartmentOptions, setShowMobileDepartmentOptions] = useState(false);

    const isMobile = useMediaQuery({ query: "(max-width: 820px)" });

    // This object contains properties that will be applied to the input
    const propsForInput = {
        placeholder: 'Search Employee...',
        role: 'checkbox',
    }

    const propsTargettingInput = {
        dir: 'ltl',
        tabIndex: 0,
        styles: {
            color: 'blue',
            background: 'white',
        },
    }

    return (
        <div className="listing-header-container">
            {/* Listing header for mobile view */}
            {isMobile &&
                <div>
                    <div className='search-input-container'>
                        <input type="text" placeholder='Search employee...'
                            onInput={(event) => {
                                setSearchText(event.target.value);
                                searchUsers(event.target.value);
                            }}
                        />
                    </div>

                    <div className='filter-container'>
                        <div className='filter-action-text-box'>
                            <button
                                className={`action-button ${showMobileDepartmentOptions ? 'action-button-active' : ''}`}
                                onClick={() => setShowMobileDepartmentOptions(!showMobileDepartmentOptions)}
                            >
                                <FilterIcon /> Click to filter by department
                            </button>
                        </div>
                        {showMobileDepartmentOptions &&
                            <div className='filter-list-box'>
                                <ul>
                                    {departments.map((department) => {
                                        return (
                                            <li
                                                className={`${department === selectedDepartment ? 'filter-item' : ''}`}
                                                onClick={(event) => {
                                                    setSelectedDepartment(event.target.innerHTML);
                                                    filterUsers(event.target.innerHTML);
                                                    searchUsers(searchText);
                                                    setShowMobileDepartmentOptions(!showMobileDepartmentOptions);
                                                }}
                                            >
                                                {department}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            }

            {/* Listing header for desktop view */}
            {!isMobile &&
                    <div className='listing-header'>
                        <div className='listing-header-dropdown'>
                            <Dropdown
                                items={departments}
                                placeholder="Filter by department"
                                checkable
                                getA11ySelectionMessage={{
                                    onAdd: item => `${item} has been selected.`,
                                }}
                                fluid
                                onChange={async (_, event) => {
                                    filterUsers(event.value);
                                    searchUsers(searchText);
                                }}
                                inverted
                            />
                        </div>

                        <div className='listing-header-search'>
                            <Input
                                icon={<SearchIcon />}
                                placeholder="Search Employee..."
                                fluid
                                onChange={(_, event) => {
                                    setSearchText(event.value);
                                    searchUsers(event.value);
                                }}
                                {...propsForInput}
                                input={propsTargettingInput}
                            />
                        </div>
                    </div>
            }
        </div>
    )
}