import "./AccessDenied.scss";
import React from "react";
import access_denied_Image from "../../images/empty-data-image.jpg";

export default function AccessDenied() {
    return (
        <div className="access-denied-container">
            <div className="access-denied-container-img">
                <img src={access_denied_Image} alt="" />
            </div>

            <div className="access-denied-container-text">
                <p>Access Denied! Please reachout to an Admin for access.</p>
            </div>
        </div>
    )
}